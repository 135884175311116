import React from "react";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";

import house from "../../assets/images/management/house-img-opacity.svg";

import "./styles.scss";
import Button from "../../shared/Button/Button";

const LEFT_BLOCK_TEXTS = [
  "Head to the property to take pictures. Go home. Write description. Spend hours posting to various sites.",
  "Take calls from too many unqualified tenants.",
  "Answer the 11:30 PM maintenance calls.",
  "Wake up at 5:00 AM to shovel.",
  "Drive over every month to collect rent. Or wait for checks in the mail.",
  "Try to build a spreadsheet to track your properties, income, and tenants. Yell at Excel."
];

function ManagementLife() {

  return (
    <div className="page-container">
      <div className="management-life">
        <div className="management-life--block management-life--left-block">
          <h3 className="management-life--title">Life Without Cornerstone
          </h3>
          {
            LEFT_BLOCK_TEXTS.map(el => (
              <p className="management-life--text" key={el}>{el}</p>
            ))
          }
        </div>
        <div className="management-life--block management-life--right-block">
          <h3 className="management-life--title">
              Life With Cornerstone
          </h3>
          <div className="management-life--inner-container">
            <p className="management-life--text management-life--text-blue">
                Check your bank account. Confirm rent received.
            </p>
            <NavLink to="/quote">
              <Button className="management-life--btn" type="primary" label="Assess My Property" />
            </NavLink>
            <LazyLoad>
              <img src={house} className="management-life--inner-container__img" alt=""/>
            </LazyLoad>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManagementLife;
