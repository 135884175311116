import React from "react";

import HeroSection from "../shared/HeroSection/HeroSection";
import ContactForm from "./components/ContactForm";

import resourcesBg from "../assets/images/resources/Bg@2x.jpg";
import resourcesBgMobile from "../assets/images/resources/resources-mobile-bg.jpg";
import { useWindowDimension } from "../../hooks/useDimensions";

function Contact() {

  const [isMobile, setMobile] = React.useState(false);
  const [width] = useWindowDimension();

  React.useEffect(() => {
    if (width > 568) setMobile(true);
    else setMobile(false)
  }, [width]);

  return (
    <div>
      <HeroSection
        image={!isMobile ? resourcesBgMobile : resourcesBg}
        imgClassname="hero-container--resources-img"
        btnClassname="hero-container--resources-btn"
        titleWrapperClassname="hero-container--resources-quote"
        title={
          <h1 className="hero-container--title">
            Contact Us
          </h1>
        }
      />
      <ContactForm />
    </div>
  )
}

export default Contact;
