import React from "react";
import { Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";

import firstHouse from "../../assets/images/home/houses/first-house.jpg";
import secondHouse from "../../assets/images/home/houses/second-house.jpg";
import thirdHouse from "../../assets/images/home/houses/thrid-house.jpg";

import "./styles.scss"
import Button from "../../shared/Button/Button";

function HomeHouses() {
  return (
    <div className="houses-container">
      <Row>
        <Row className="houses-container--row-reverse">
          <Col className="houses-container--text-block" lg={24} xl={12}>
            <div className="houses-container--text-block__inner first-text-block">
              <h3 className="houses-container--text-block__title">The single most effective <br/> way to feel confident in your <br/> Hudson Valley investment</h3>
              <p className="houses-container--text-block__text">You didn’t invest to get consumed by the day-to-day of your property. We give you the reliable, time-tested management solution you need to cut ties with daily frustrations. It’s your property. But it’s our responsibility.</p>
              <NavLink to="/services/management">
                <Button className="houses-container--text-block__btn" type="primary" label="I Need to Know More"/>
              </NavLink>
            </div>
          </Col>
          <Col xl={12} lg={24} className="overflow-hidden">
            <LazyLoad>
              <img className="houses-container--text-block__img" src={firstHouse} alt="Ulster Performing Arts Center"/>
            </LazyLoad>
          </Col>
        </Row>
        <Col xl={12} lg={24} className="second-house-tablet overflow-hidden">
          <LazyLoad>
            <img className="houses-container--text-block__img" src={secondHouse} alt="Rosendale Theater"/>
          </LazyLoad>
        </Col>
        <Col className="houses-container--text-block" xl={12} lg={24}>
          <div className="houses-container--text-block__inner second-text-block">
            <h3 className="houses-container--text-block__title">Because your home or <br/> business isn’t just a location</h3>
            <p className="houses-container--text-block__text">You want to put your heart into your home or business property. Our expert team of realtors has the experience and resources to help you find your perfect spot. Sail through your search with local, trusted, and knowledgeable guidance.</p>
            <NavLink to="/services/sales">
              <Button className="houses-container--text-block__btn" type="primary" label="Help Me Find My Spot"/>
            </NavLink>
          </div>
        </Col>
        <Row className="houses-container--row-reverse">
          <Col className="houses-container--text-block" lg={24} xl={12}>
            <div className="houses-container--text-block__inner third-text-block">
              <h3 className="houses-container--text-block__title">When you need to build <br/> your own dream</h3>
              <p className="houses-container--text-block__text">Sometimes perfection means starting from the ground up or reinventing on beautiful bones. And you don’t need to search for builders to make that happen. Our in-house construction and renovation team means the experience you need is right here.</p>
              <NavLink to="/services/building">
                <Button className="houses-container--text-block__btn houses-container--text-block__last-btn" type="primary" label="Build or Renovate My Dream"/>
              </NavLink>
            </div>
          </Col>
          <Col xl={12} lg={24} className="overflow-hidden">
            <LazyLoad>
              <img className="houses-container--text-block__img" src={thirdHouse} alt="Mid Hudson Bridge"/>
            </LazyLoad>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default HomeHouses;
