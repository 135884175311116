import React from "react";

import HeroSection from "../shared/HeroSection/HeroSection";
import ResourcesApply from "./components/ResourcesApply";
import ResourcesGuideline from "./components/ResourcesGuidline";

import resourcesBg from "../assets/images/resources/Bg@2x.jpg";
import resourcesBgMobile from "../assets/images/resources/resources-mobile-bg.jpg";
import { useWindowDimension } from "../../hooks/useDimensions";
import Helmet from "react-helmet";

function Resources() {

  const [isMobile, setMobile] = React.useState(false);
  const [width] = useWindowDimension();

  React.useEffect(() => {
    if (width > 568) setMobile(true);
    else setMobile(false)
  }, [width]);

  return (
    <div>
      <Helmet>
        <title>Property Management Resources | Cornerstone Property Management</title>
        <meta property="title" content="Property Management Resources | Cornerstone Property Management "/>
        <meta property="description" content="Should you hire a property manager? Is it better to rent or buy? You’ve got questions. Grab one of our free downloads for your answers."/>
      </Helmet>
      <HeroSection
        linkOuter="https://cornerstonemgmt.managebuilding.com/Resident/portal/login"
        image={!isMobile ? resourcesBgMobile : resourcesBg}
        imgClassname="hero-container--resources-img"
        btnClassname="hero-container--resources-btn"
        titleWrapperClassname="hero-container--resources"
        title={
          <h1 className="hero-container--title">
            Your Resources
          </h1>
        }
        label="Existing Residents" />
      <ResourcesApply />
      <ResourcesGuideline />
    </div>
  )
}

export default Resources;
