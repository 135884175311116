import React from "react";
import Helmet from "react-helmet";
import GeneralLayout from "../shared/layouts/GeneralLayout";

function Rentals({ iframeUrl, title }: { iframeUrl: string, title: string }) {

  return (
    <div className="page-container">
      <Helmet>
        <title>
            Hudson Valley Residential + Commercial Sales | Find Your Home or Business | Cornerstone Property Management
        </title>
        <meta property="title" content="Hudson Valley Residential + Commercial Sales | Cornerstone Property Management | Find Your Home or Business"/>
        <meta property="description" content="You know your perfect Hudson Valley spot is out there. You just want someone to help you find it. Put the real estate team at Cornerstone to work finding your dream home or business property."/>
      </Helmet>
      <h1 className="page-container--title">{ title }</h1>
      <iframe src={iframeUrl} width="100%"
        height="600" frameBorder="0"></iframe>
    </div>
  )
}

export default Rentals;
