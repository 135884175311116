import React from "react";
import Helmet from "react-helmet";

import HeroSection from "../shared/HeroSection/HeroSection";
import ContactForm from "./components/ContactForm";

import resourcesBg from "../assets/images/resources/Bg@2x.jpg";
import resourcesBgMobile from "../assets/images/resources/resources-mobile-bg.jpg";
import { useWindowDimension } from "../../hooks/useDimensions";

function Contact() {

  const [isMobile, setMobile] = React.useState(false);
  const [width] = useWindowDimension();

  React.useEffect(() => {
    if (width > 568) setMobile(true);
    else setMobile(false)
  }, [width]);

  return (
    <div>
      <Helmet>
        <title>Cornerstone Property Management | Contact Us</title>
        <meta property="title" content="Your Ultimate Hudson Valley Property Management Team | Building, Restoration, Rentals + Sales | Cornerstone Property Management"/>
        <meta property="description" content="This is your chance to get connected with the Hudson Valley’s most complete property management team. Get in touch for rental property management, HOA/condo management, properties for sale, and renovation and construction."/>
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Poughkeepsie",
            "postalCode": "NY 12601",
            "streetAddress": "560 Main St"
          },
          "email": "OFFICE@CORNERSTONEMGMT.US",
          "name": "Cornerstone",
          "telephone": "845 501 4141"
        }
      `}</script>
      </Helmet>
      <HeroSection
        image={!isMobile ? resourcesBgMobile : resourcesBg}
        imgClassname="hero-container--resources-img"
        btnClassname="hero-container--resources-btn"
        titleWrapperClassname="hero-container--resources-quote"
        title={
          <h1 className="hero-container--title">
            Contact Us
          </h1>
        }
      />
      <ContactForm />
    </div>
  )
}

export default Contact;
