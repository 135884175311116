import React from "react";

import people from "../../assets/images/hoa/people.svg"
import heart from "../../assets/images/hoa/heart.svg"
import dolar from "../../assets/images/hoa/dolar.svg"
import note from "../../assets/images/hoa/note.svg"

import "./styles.scss";

import Table from "../../shared/Table/Table";

const TABLE_ITEMS = [
  {
    title: "Delegate the admin",
    text: "Let go of the nitty-gritty. We’ll handle enforcement of policies, communication with residents,\n" +
            " record maintenance, long-term improvement planning, document preparation.\n" +
            "All the tasks that weigh you and your Board down.",
    img: people
  },
  {
    title: "Don’t fumble the finances",
    text: "Imagine having the time and information to make better financial decisions for your community. When you’re not wrapped up in assisting residents with payments, managing accounts, and preparing statements, you will.",
    img: dolar
  },
  {
    title: "Keep happy residents",
    text: "Your reputation rests on happy residents. And a well-run, well-maintained community makes residents happy. Site maintenance, emergency work orders, professional quotes, long-term projects, residential requests  – all handed off to your team of experts.",
    img: heart
  },
  {
    title: "Lean on experienced guidance",
    text: "Your Board is going to love you. You found a way to be walked through setting, updating, and enforcing guidelines, understanding changes in condo and HOA legislation, navigating resident disputes, even planning next year’s budget.",
    img: note
  }
];

function HOATable() {

  return (
    <div className="page-container">
      <div className="hoa-table">
        <h2 className="hoa-table--title">
            Not just another company that collects money.
        </h2>
        <Table items={TABLE_ITEMS} />
      </div>
    </div>
  )
}

export default HOATable;
