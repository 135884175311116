import React from "react";
import { Col, Row, Image } from "antd";
import { NavLink } from "react-router-dom";

import owner from "../../assets/images/about/about-owner.png";
import president from "../../assets/images/about/about-president.png";

import "./styles.scss";
import aqualHousingIcon from "../../assets/images/about/equal-housing.jpg";

import Button from "../../shared/Button/Button";

function AboutTeam() {

  return (
    <div className="page-container">
      <div className="about-team-container">
        <h2 className="about-team-container--title">Your team should be as invested as you.</h2>
        <p className="about-team-container--text">The leadership team at Cornerstone has invested years into becoming experts in their work. And that <br /> means when you partner with them, you can lean on their experience, too.</p>
      </div>

      <Row className="about-team-container--people-section">
        <Col xl={12}>
          <div className="about-team-container--block-container">
            {/* <img className="about-team-container--img" src={owner} alt=""/> */}
            <div className="about-team-container--description-container">
              <span className="about-team-container--name">Roy Jacobs</span>
              <span className="about-team-container--job">Principal Broker + Owner</span>
              <p className="about-team-container--description">
                Roy has been involved in real estate and property management for over 35 years, building his first business into the largest property management company in Newburgh within two years. He has successfully managed a range of property types, including office buildings, strip malls, co-ops, and condos as well single-family rentals and sales. Roy’s depth of experience has earned him the moniker “Your rental property specialist”.
              </p>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <div className="about-team-container--block-container m-0">
            {/* <img className="about-team-container--img" src={president} alt=""/> */}
            <div className="about-team-container--description-container">
              <span className="about-team-container--name">Stefan Bohdanowycz</span>
              <span className="about-team-container--job">President</span>
              <p className="about-team-container--description">
                Stefan has run <a className="about-team-container--link" target="_blank" href="https://www.tricontractinginc.com">a successful construction company</a> in New York City for 20 years, managing projects ranging from ground-up construction to complete rehabs of prewar buildings. Landlords and real estate managers rely on Stefan’s firm to maintain their buildings, including apartment renovations, structural reinforcements, and apartment turnovers. More recently, Stefan’s love of restoration has led him to begin developing properties in the Hudson Valley, particularly in the renovation and modernization of century-old, multifamily dwellings.
              </p>
            </div>
          </div>
        </Col>
      </Row>

      <div className="about-team-container--btn-container">
        <NavLink to="/quote">
          <Button className="about-team-container--quote-btn" label=" I’m Ready for a Quote" />
        </NavLink>
        <NavLink to="/contact">
          <Button className="about-team-container--talk-btn" label="I’d Like to Talk First" type="primary" />
        </NavLink>
      </div>

      <Row>
        <Col xs={6} xl={4} style={{ padding: "20px" }}>
          <Image src={aqualHousingIcon} preview={false} alt={"Equal Housing Ppportunity Image"}></Image>
        </Col>
        <Col xs={18} xl={20} style={{ padding: "20px" }}>
          <h3>
            The Fair Housing Act prohibits any preference, limitation, or discrimination because of race, color, religion,
            sex, handicap, familial status, or national origin or intention to make such preference, limitation, or discrimination.
            Cornerstone Property Management proudly complies with this regulation.
          </h3>
        </Col>
      </Row>
    </div>
  )
}

export default AboutTeam;
