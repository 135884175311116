import React from "react";

import HomeHero from "./components/HomeHero";
import HomeServices from "./components/HomeServices";
import HomeHouses from "./components/HomeHouses";
import HomeTeam from "./components/HomeTeam";
import Reviews from "../shared/Reviews/Reviews";

function Home() {

  return (
    <>
      <HomeHero/>
      <HomeServices/>
      <HomeHouses/>
      <HomeTeam/>
      <Reviews/>
    </>
  )
}

export default Home;
