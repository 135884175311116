import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import TextArea from "../../shared/TextArea/TextArea";
import Button from "../../shared/Button/Button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import emailjs from "emailjs-com";

function GetQuoteSecondForm({ email }: { email: string }) {

  const [loading, setLoading] = React.useState(false);
  const [isSubmited, setSubmited] = React.useState(false);

  const schema = yup.object().shape({
    comments: yup.string().required("Field is required").matches(/.*\S.*/, "Field is required")
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = (values: any) => {

    values.type = "Quote";
    values.email = email;
    sendFeedback(values)
    methods.reset({});
  };


  const sendFeedback = (values: any) => {
    setLoading(true)
    emailjs
      .send(
            process.env.REACT_APP_SERVICE_ID as string,
            process.env.REACT_APP_TEMPLATE_ID as string,
            values,
            process.env.REACT_APP_USER_ID)
      .then(() => setSubmited(true))
      .finally(() => setLoading(false))
  };

  return (
    <div className="get-quote-container--second-step">
      <h3 className="get-quote-container--second-step__title">
          Thank you!
      </h3>
      <p className="get-quote-container--second-step__text">
          Your quote was submitted successfully. We will get <br className="crn-br-desktop"/> back to you shortly.
      </p>
      <p className="get-quote-container--second-step__text-second">
          Now that you're on your way to a quote, what do you most want to gain by working with Cornerstone?
      </p>
      <FormProvider {...methods}>
        <form className="get-quote-container--second-step__form">
          <TextArea className="get-quote-container--input" name="comments" label="Comments" placeholder="Message" />
          <div className="get-quote-container--second-step__btn-container">
            <Button
              loading={loading}
              onClick={methods.handleSubmit(onSubmit)}
              className="get-quote-container--second-step__btn"
              label="Submit"
              type="primary"
            />
            {
              isSubmited && (
                <span className="success-message">We have successfully sent your comments!</span>
              )
            }
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default GetQuoteSecondForm
