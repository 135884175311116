import React from "react";
import { Input } from "antd";
import { useFormContext, Controller } from "react-hook-form";

import "./styles.scss";

type InputWrapper = {
    label?: string;
    placeholder: string;
    className?: string;
    name: string;
}

function InputWrapper({ label, placeholder, className, name }: InputWrapper) {

  const methods = useFormContext();

  const classNames = ["crn-input"];

  if (className) classNames.push(className);

  return (
    <div className={classNames.join(" ")}>
      <label className="crn-input--label">{label}</label>
      <Controller
        name={name}
        control={methods.control}
        as={<Input placeholder={placeholder}/>}
      />
        { methods.errors[name] && <span className="error-message">{methods.errors[name].message}</span>}
    </div>
  )
}

export default InputWrapper;
