import React from "react";
import { slide as Menu } from "react-burger-menu";
import AnimateHeight from "react-animate-height";
import { NavLink, useLocation } from "react-router-dom";

import "./styles.scss"

import arrowMobile from "../../assets/images/home/arrow.svg";

function Burger({ open, setOpen }: { open: boolean; setOpen: (e: boolean) => void }) {

  const [isServicesOpen, setServices] = React.useState(false);
  const [isPropertyOpen, setProperty] = React.useState(false);
  const [servicesHeight, setServicesHeight] = React.useState<string | number>(0);
  const [properyHeight, setProperyHeight] = React.useState<string | number>(0);


  const toggleHeightServices = () => {
    setServices(!isServicesOpen);
    setServicesHeight(servicesHeight === 0 ? "auto" : 0);
  };

  const toggleHeightProperty = () => {
    setProperty(!isPropertyOpen);
    setProperyHeight(properyHeight === 0 ? "auto" : 0);
  };

  const location = useLocation();


  React.useEffect(() => {
    setOpen(false)
  }, [location]);


  return (
    <Menu
      isOpen={open}
      burgerButtonClassName={open ? "open" : "closed"}
      onStateChange={(e) => setOpen(e.isOpen)}
    >
      <div>
        <div onClick={toggleHeightServices} className="header-container--menu-sub-container">
          <span id="home" className="menu-item">Services</span>
          <img
            className={`header-container--menu-sub-container__img
            ${isServicesOpen && "header-container--menu-sub-container__rotated"} `}
            src={arrowMobile} alt=""
          />
        </div>
        <AnimateHeight height={servicesHeight}>
          <div className={`header-container--inner-block ${isServicesOpen && "header-container--inner-block-open"} `}>
            <p className="houses-container--inner-block__text">
              <NavLink to="/services/management">Property Management </NavLink>
            </p>
            <p className="houses-container--inner-block__text">
              <NavLink to="/services/hoa">Conda/Hoa Management</NavLink>
            </p>
            <p className="houses-container--inner-block__text">
              <NavLink to="/services/sales">Residential + Commercial Sales</NavLink>
            </p>
            <p className="houses-container--inner-block__text">
              <NavLink to="/services/building">Construction + Restoration</NavLink>
            </p>

          </div>
        </AnimateHeight>
      </div>
      <div>
        <div onClick={toggleHeightProperty} className="header-container--menu-sub-container">
          <span id="about" className="menu-item">Properties</span>
          <img
            className={`header-container--menu-sub-container__img
             ${isPropertyOpen && "header-container--menu-sub-container__rotated"} `}
            src={arrowMobile} alt=""
          />
        </div>
        <AnimateHeight height={properyHeight}>
          <div className={`header-container--inner-block ${isPropertyOpen && "header-container--inner-block-open"} `}>
            <p className="houses-container--inner-block__text">
              <NavLink to="/rentals">
                Commercial
              </NavLink>
            </p>
            <p className="houses-container--inner-block__text">
              <NavLink to="/services/sales">
                Residential
              </NavLink>
            </p>
          </div>
        </AnimateHeight>
      </div>
      <NavLink className="menu-item" to="/about">About Us</NavLink>
      <NavLink className="menu-item" to="/quote">Get a Quote</NavLink>
      <NavLink className="menu-item" to="/contact">Contact Us</NavLink>
      <NavLink className="menu-item" to="/resources">Resources</NavLink>
    </Menu>
  )
}

export default Burger;
