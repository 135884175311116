import React from "react";
import { Row, Col } from "antd";

import "./styles.scss";

import quote from "../../assets/images/home/houses/quote.svg";

const reviews = [
  {
    name: "Stanlay Desir",
    rank: "",
    text: `Before I met Roy, my family and I were sitting on two properties with several units that went months without a soul to rent them. 
      Within the first 2 months of doing business with Cornerstone Property Management, all my units were rented. 
      My contractors couldn't even keep up with the speed in which Roy was able to drum up premarket interest and find me Trustworthy reliable 
      Tenants that have, thus far, never paid late. The team at Cornerstone isn't just reliable and trustworthy professionals, they are family and we're 
      lucky to have them.`
  },
  {
    name: "Lucent Strategies, LLC",
    rank: "",
    text: `Since 2014, Roy Jacobs has consistently brought terrific tenants to our historic downtown Poughkeepsie buildings. 
      He understands our commitment to the  diverse local community, and knows the unique character of our properties. 
      Roy's style is a rare blend of seasoned business acumen, authentic kindness, and high professional standards. 
      We trust his insights and greatly value his assistance.`
  }
]


function Reviews({ className }: { className?: string }) {

  const classNames = ["reviews-container--title"];

  if (className) classNames.push(className);

  return (
    <div className="reviews-container page-container">
      <h2 className={classNames.join(" ")}>You’re in good <br className="reviews-container--mobile-br" /> company</h2>
      <Row className="reviews-container--main">
        {
          reviews.map((el, key) => {
            return (
              <Col key={key} md={12} xl={8}>
                <div className="reviews-container--block">
                  <p className="reviews-container--block__text">
                    {el.text}
                  </p>
                  <div className="reviews-container--divider" />

                  <p className="reviews-container--block__name">
                    {el.name}
                  </p>
                  <p className="reviews-container--block__rank">
                    {el.rank}
                  </p>
                  <img className="reviews-container--block__img" src={quote} alt="" />
                </div>
              </Col>
            )
          })
        }
      </Row>
    </div>
  )
}

export default Reviews;
