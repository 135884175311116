import React from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";

import Home from "./Home/Home";
import HOA from "./HOA/HOA";
import Building from "./Building/Building";
import Management from "./Management/Management";
import Sales from "./Sales/Sales";
import Rentals from "./Rentals/Rentals";
import Property from "./Property/Property";
import About from "./About/About";
import Resources from "./Resources/Resources";
import GetQuote from "./GetQuote/GetQuote";
import Contact from "./Contact/Contact";
import ContactThankYou from "./ContactThankYou/Contact";


import ComingSoon from "./ComingSoon/ComingSoon";

//layouts
import GeneralLayout from "./shared/layouts/GeneralLayout";
import ComingSoonLayout from "./shared/layouts/ComingSoonLayout";


// Helper
const render = (
  Component: React.ComponentType<RouteComponentProps>, Layout = GeneralLayout
) => (props: RouteComponentProps<{ [key: string]: string }>) => {
  const extraProps: { key?: string } = {};

  return (
    <Layout {...extraProps}>
      <Component {...props} />
    </Layout>
  )
};

const rentalRender = ({ iframeUrl, title }: { iframeUrl: string; title: string }) => {
  return (
    <GeneralLayout>
      {Rentals({ iframeUrl, title })}
    </GeneralLayout>
  )
}

function Routes() {

  return (
    <Switch>
      {/* <Route path="/" exact render={render(ComingSoon, ComingSoonLayout)}/> */}
      <Route path="/" exact render={render(Home)} />
      <Route path="/services/hoa" exact render={render(HOA)} />
      <Route path="/services/building" exact render={render(Building)} />
      <Route path="/services/management" exact render={render(Management)} />
      <Route path="/services/sales" exact render={render(Sales)} />
      <Route
        path="/rentals"
        render={() => rentalRender({ title: "Available Residential Properties", iframeUrl: "https://cornerstonemgmt.managebuilding.com/Resident/Public/Rentals?hidenav=true" })}
        exac
      />
      <Route
        path="/rentals-commercial"
        render={() => rentalRender({ title: "Available Commercial Properties", iframeUrl: "https://cornerstonemgmt.managebuilding.com/Resident/Public/Rentals?hidenav=true&type=Industrial" })}
        exac
      />
      {/*<Route path="/rentals/:id" exact render={render(Property)} />*/}
      <Route path="/about" exact render={render(About)} />
      <Route path="/resources" exact render={render(Resources)} />
      <Route path="/quote" exact render={render(GetQuote)} />
      <Route path="/quote-thankyou" exact render={render(GetQuote)} />
      <Route path="/contact" exact render={render(Contact)} />
      <Route path="/contact-thankyou" exact render={render(ContactThankYou)} />
    </Switch>
  )
}

export default Routes;
