import React from "react";

import downloadIcon from "../../assets/images/download.svg"
import own from "../../assets/pdf/own.pdf";
import rent from "../../assets/pdf/rent.pdf";
import reasonsToWork from "../../assets/pdf/Top_4_reasons_to_work_with_a_property_manager.pdf";
import needToKnow from "../../assets/pdf/What_you_need_to_know.pdf";

import "./styles.scss";

function LearnMore({ page }: { page?: string }) {
  return (
    <div className="page-container">
      <div className="learn-more">
        <h2 className="learn-more--title">
          Resources to Guide Your Hudson Valley Home Search
        </h2>
        {itemsContainer({ page })}
      </div>
    </div>
  )
}

export function itemsContainer({ page }: { page?: string }) {

  let ITEMS;

  const top4reasons = { label: "Top 4 Reasons to Work with a Property Manager", img: downloadIcon, link: reasonsToWork }
  const whatYouNeedToknow = { label: "What You Need to Know", img: downloadIcon, link: needToKnow }
  const benefitsOfOwning = { label: "The benefits of owning your home", img: downloadIcon, link: own }
  const benefitsOfRenting = { label: "The benefits of renting your home", img: downloadIcon, link: rent }

  if (page === "property_management") {
    ITEMS = [top4reasons, whatYouNeedToknow]
  } else if (page === "resourses") {
    ITEMS = [top4reasons, whatYouNeedToknow, benefitsOfOwning, benefitsOfRenting]
  } else {
    ITEMS = [benefitsOfOwning, benefitsOfRenting]
  }

  return (
    <div className="learn-more--inner-container">

      {
        ITEMS.map(({ label, img, link }, key) => (
          <a key={key} target="_blank" rel="noopener noreferrer" href={link}>
            <div className="learn-more--inner-container__item">
              <img className="learn-more--inner-container__img" src={img} alt="" />
              <span className="learn-more--inner-container__text">{label}</span>
            </div>
          </a>
        ))
      }
    </div>
  )
}

export default LearnMore;
