import React from "react";

import "./styles.scss";

function ResourcesApply() {

  return (
    <div className="page-container">
      <div className="resources-apply-container">
        <h2 className="resources-apply-container--main-title">Log In or Apply Now</h2>
        <div className="resources-apply-container--header">
          <a className="top-block-link" href="https://cornerstonemgmt.managebuilding.com/Resident/portal/login" target="_blank" rel="noreferrer noopener">
            <div className="resources-apply-container--top-block">
              <h3 className="resources-apply-container--top-title">
                Existing Residents
              </h3>
              <p className="resources-apply-container--top-text">
                Log in to submit maintenance requests <br className="crn-br-desktop" /> or pay rent.
              </p>
            </div>
          </a>
          <a className="top-block-link" href="https://www.tricontractinginc.com" target="_blank" rel="noreferrer noopener">
            <div className="resources-apply-container--top-block">
              <h3 className="resources-apply-container--top-title">
                Prospective Residents
              </h3>
              <p className="resources-apply-container--top-text">
                Take the first step towards your new home <br className="crn-br-desktop" /> and apply today.
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ResourcesApply;
