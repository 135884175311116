import React from "react";
import { Row, Col } from "antd";

import firstImg from "../../assets/images/services/const-1.svg";
import secondImg from "../../assets/images/services/const-2.svg";
import thirdImg from "../../assets/images/services/const-3.svg";
import fourtImg from "../../assets/images/services/const-4.svg";
import fifthImg from "../../assets/images/services/const-5.svg";
import sixImg from "../../assets/images/services/const-6.svg";

import "./styles.scss";

const SECTIONS = [
  {
    label: "New builds",
    img: firstImg
  },
  {
    label: "Structural repairs and additions",
    img: secondImg
  },
  {
    label: "Façade work",
    img: thirdImg
  },
  {
    label: "Roofing",
    img: fourtImg
  },
  {
    label: "Plumbing",
    img: fifthImg
  },
  {
    label: "Electrical",
    img: sixImg
  }
];

function BuildingRenovation() {

  return (
    <div className="page-container">
      <div className="building-renovation">
        <h2 className="building-renovation--title">
            Your construction + renovation solution
        </h2>
        <Row className="building-renovation--inner-container">
          {
            SECTIONS.map(({ label, img }, key) => (
              <Col key={key} xl={8} md={24} sm={24} xs={24}>
                <div className="building-renovation--item">
                  <div className="building-renovation--circle">
                    <img src={img} alt=""/>
                  </div>
                  <p className="building-renovation--text">
                    {label}
                  </p>
                </div>
              </Col>
            ))
          }
        </Row>
      </div>
    </div>
  )
}

export default BuildingRenovation;
