import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import emailjs from "emailjs-com";


import "./styles.scss";

import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";
import TextArea from "../../shared/TextArea/TextArea";
import GetQuoteSecondForm from "./GetQuoteSecondForm";
import { useHistory, useLocation } from "react-router";


function GetQuoteForm() {

  const [isSubmited, setSubmited] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const schema = yup.object().shape({
    name: yup.string().required("Field is required").matches(/.*\S.*/, "Field is required"),
    email: yup.string().required("Field is required")
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Wrong email format"),
    comments: yup.string().required("Field is required").matches(/.*\S.*/, "Field is required")
  });

  const history = useHistory();
  const location = useLocation();

  const methods = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = (values: any) => {

    setEmail(values.email);
    values.type = "Quote";
    sendFeedback(values)
  };


  const sendFeedback = (values: any) => {
    setLoading(true)
    emailjs
      .send(
            process.env.REACT_APP_SERVICE_ID as string,
            process.env.REACT_APP_TEMPLATE_ID as string,
            values,
            process.env.REACT_APP_USER_ID)
      .then(() => {
        setSubmited(true);
        window.scrollTo(0, 0);
      })
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    if (!isSubmited) return;

    history.push(`/quote-thankyou?email=${email}`)

  }, [isSubmited]);

  return (
    <div className="page-container">
      <div className="get-quote-container">
        {
          location.pathname === "/quote"
            ? (
              <div>
                <h2 className="get-quote-container--title">Get a Quote</h2>
                <div className="get-quote-container--form">
                  <FormProvider {...methods}>
                    <form>
                      <Input className="get-quote-container--input" name="name" placeholder="Your name" label="Name"/>

                      <Input className="get-quote-container--input" name="email" placeholder="Email" label="Email"/>

                      <Input className="get-quote-container--input" name="phone" placeholder="Phone number" label="Phone #"/>

                      <TextArea className="get-quote-container--input" name="comments" label="Comments" placeholder="Message" />

                      <div className="get-quote-container--btn-container text-center">
                        <Button
                          loading={loading}
                          onClick={methods.handleSubmit(onSubmit)}
                          className="get-quote-container--btn"
                          label="Submit"
                          type="primary"
                        />
                      </div>
                    </form>
                  </FormProvider>
                </div>
              </div>

            )
            : <GetQuoteSecondForm email={email}/>
        }
      </div>
    </div>
  )
}

export default GetQuoteForm;
