import React from "react";
import { NavLink } from "react-router-dom";

import Table from "../../shared/Table/Table";
import Button from "../../shared/Button/Button";

import "./styles.scss";

import first from "../../assets/images/sales/sales-house-icon.svg";
import second from "../../assets/images/sales/sales-note-icon.svg";
import third from "../../assets/images/sales/sales-heart-icon.svg";

const TABLE_ITEMS = [
  {
    title: "You don’t want what everyone else wants",
    text: "You know how you’ve been picturing your perfect property in your head for months now? Your Cornerstone real estate team invests time upfront to see that vision with you. When we understand what you want, we’ll launch your search.",
    img: first
  },
  {
    title: "You need hand-picked properties",
    text: "You’ve got the vision, now you need the properties to match…without wading through the irrelevant ones. Jumpstart your search with listings we’ll select to align with what you’re looking for.",
    img: second
  },
  {
    title: "You’ll have Negotiation Navigation",
    text: "Making your offer and weaving through the potential negotiations can be harder than finding the property itself. Navigate it effortlessly with the guidance of the Cornerstone realtors.",
    img: third
  },
];

function SalesTable() {

  return (
    <div className="page-container">
      <div className="sales-table">
        <h2 className="sales-table--title">
            If you could build your real estate <br className="crn-br-desktop"/> dream team, it’d look a lot like us
        </h2>

        <Table items={TABLE_ITEMS} />

        <NavLink to="/rentals">
          <Button className="sales-table--btn" type="primary" label="Available Properties, Please!" />
        </NavLink>
      </div>
    </div>
  )
}


export default SalesTable;
