import React from "react";
import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";

import "./styles.scss";

import house from "../../assets/images/hoa/house.svg"

import Button from "../../shared/Button/Button";
import Helmet from "react-helmet";

function HOAManagement() {

  return (
    <div className="page-container">
      <Helmet>
        <title>Hudson Valley Condo + HOA Management Specialists | Cornerstone Property Management</title>
        <meta property="
        title" content="Hudson Valley Condo + HOA Management Specialists | Cornerstone Property Management"/>
        <meta property="description" content="Make Condo and HOA Management less of a headache. Admin and financial support, maintenance, and the guidance you need to easily navigate your community’s success."/>
      </Helmet>
      <Row className="hoa-management">
        <Col xl={16} md={24}>
          <h2 className="hoa-management--title">
              Condo and HOA Management is <br/> a headache. We’re your aspirin.
          </h2>
          <p className="hoa-management--first-text">
              You had no idea how much work your condo or home <br/> owner’s association was going to be.
              The emergency <br/> repairs, the maintenance, the residents’ issues.
          </p>
          <p className="hoa-management--second-text">
              Imagine if you woke up tomorrow morning…and all of that was off your plate. <br/>
              Make it happen by partnering with a team ready to navigate condo and HOA <br/> management for you.
          </p>
          <NavLink to="/quote">
            <Button className="hoa-management--btn" label="I Need That Please" type="primary"/>
          </NavLink>
        </Col>
        <Col xl={8} md={24}>
          <div className="hoa-management--grey-container">
            <img className="hoa-management--grey-container__img" src={house} alt="Condo and HOA Management Image"/>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HOAManagement;
