import React from "react";
import { Row, Col } from "antd";

import "./styles.scss";

type Table = {
    items: {[key: string]: any} [];
    isDouble?: boolean;
}

function Table({ items, isDouble = false }: Table) {

  return (
    <div className="table--content">
      {
        isDouble ? (
          <Row>
            {
              items.map(({ title, text, img }, key) => {

                const className = ["table--content__item table--content__height"];

                if (key % 2 === 0) className.push("table--content__border")

                return (
                  <Col xl={12} key={key}>
                    <div className={className.join(" ")}>
                      <div className="table--content__inner">
                        <div className="table--content__circle">
                          <img src={img} alt=""/>
                        </div>
                        <h3 className="table--content__title">{title}</h3>
                      </div>
                      <p className="table--content__text">{text}</p>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        ) : (
          <>
            {
              items.map(({ title, text, img }, key) => (
                <div key={key} className="table--content__item">
                  <div className="table--content__inner">
                    <div className="table--content__circle">
                      <img src={img} alt=""/>
                    </div>
                    <h4 className="table--content__title">{title}</h4>
                  </div>
                  <p className="table--content__text">{text}</p>
                </div>
              ))
            }
          </>
        )
      }
    </div>
  )
}

export default Table;
