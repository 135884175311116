import React from "react";

import HeroSection from "../shared/HeroSection/HeroSection";
import SalesWork from "./components/SalesWork";

import salesBg from "../assets/images/sales/sales-bg.jpg";
import SalesTable from "./components/SalesTable";
import LearnMore from "../shared/LearnMore/LearnMore";
import Helmet from "react-helmet";


function Sales() {

  return (
    <div>
      <Helmet>
        <title>Hudson Valley Residential + Commercial Sales |
            Cornerstone Property Management | Find Your Home or Business</title>
        <meta property="title" content="Hudson Valley Residential + Commercial Sales | Cornerstone Property Management | Find Your Home or Business"/>
        <meta property="description" content="You know your perfect Hudson Valley spot is out there. You just want someone to help you find it. Put the real estate team at Cornerstone to work finding your dream home or business property."/>
      </Helmet>
      <HeroSection
        link="/rentals"
        image={salesBg}
        titleWrapperClassname="hero-container--management"
        title={
          <h1 className="hero-container--title">
              Hudson Valley Home + Business Property Sales
          </h1>
        }
        label="Find My Property"/>
      <SalesWork/>
      <SalesTable/>
      <LearnMore/>
      <div className="page-container page-container_last">
        <h2 className="sales-work--title">Cornerstone Realty Group LLC requires <br className="crn-br-desktop"/> all this information for
          prospective purchases regarding three questions:</h2>
        <p className="sales-work--second-text">
          (1) required prospective clients shall show identification, (2) required an exclusive broker agreement
          is required, and (3) required pre-approval for a mortgage loan is required.
        </p>
        <br/>
        <p className="sales-work--second-text">
          To be clear, a real estate broker is simply required to let purchasers know required or
          not they will require any one, two or three of these items.
        </p>
      </div>
    </div>
  )
}

export default Sales;
