import React from "react";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";

import hero from "../../assets/images/home/home-hero.jpg";

import "./styles.scss";

import Button from "../../shared/Button/Button";
import Helmet from "react-helmet";


function HomeHero() {

  return (
    <div className="page-container">
      <Helmet>
        <title>Hudson Valley Property Management | Construction + Restoration | Cornerstone Management</title>
        <meta property="title" content="Hudson Valley Property Management | Construction + Restoration | Cornerstone Management"/>
        <meta property="description" content="You need reliable feet on the ground managing your Hudson Valley investment. Full-service property manager, in-house construction and restoration, and expert residential and commercial rental and sale representation."/>
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org/",
          "@type": "Service",
          "serviceType": "Cornerstone Property Management",
          "provider": {
            "@type": "Organization",
            "name": "Cornerstone",
            "telephone": "845 501 4141",
            "hasOfferCatalog": {
            "@type": "OfferCatalog",
            "itemListElement": [
              {
                "@type": "OfferCatalog",
                "name": "Hudson Valley Property Management"
              },
              {
                "@type": "OfferCatalog",
                "name": "Construction"
              },
              {
                "@type": "OfferCatalog",
                "name": "Rentals Sales"
              },
              {
                "@type": "OfferCatalog",
                "name": "Building And Restoration"
              }
            ]
          }
          }
        }
      `}</script>
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Poughkeepsie",
            "postalCode": "NY 12601",
            "streetAddress": "560 Main St"
          },
          "email": "OFFICE@CORNERSTONEMGMT.US",
          "name": "Cornerstone",
          "telephone": "845 501 4141"
        }
      `}</script>
      </Helmet>
      <div className="home-hero">
        <h1 className="home-hero--title">
            Cornerstone Property <br className="home-hero--title-mobile-br"/> Management: Hudson Valley Property Management, Construction, <br className="br-none"/> Rentals, Sales
        </h1>
        <div className="home-hero--mobile-container">
          <div>
            <div className="home-hero--divider-container">
              <p className="home-hero--text">
                  The Hudson Valley’s most <br className="home-hero--title-mobile-br"/> complete property  management service.
              </p>
              <div className="home-hero--divider"/>
            </div>
            <div className="home-hero--btn-container">
              <NavLink to="/rentals">
                <Button className="home-hero--rentals" label="Available Rentals"/>
              </NavLink>
              <NavLink to="/quote">
                <Button label="Get a quote" className="home-hero--quotes"/>
              </NavLink>
            </div>
          </div>
          <LazyLoad once>
            <img className="home-hero--img" src={hero} alt="Image for The Hudson Valley’s most complete property management service"/>
          </LazyLoad>
        </div>
      </div>
    </div>
  )
}

export default HomeHero;
