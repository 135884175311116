import React from "react";

import "./styles.scss";

function ManagementBottomBanner() {

  return (
    <div className="management-banner">
      <div className="page-container">
        <h3 className="management-banner--title">
            Your property should work for you. We’re here to make that happen
        </h3>
      </div>

    </div>
  )
}

export default ManagementBottomBanner;
