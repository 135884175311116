import React from "react";

import HeroSection from "../shared/HeroSection/HeroSection";
import ManagementWork from "./components/ManagementWork";
import ManagementTable from "./components/ManagementTable";
import LearnMore from "../shared/LearnMore/LearnMore";
import ManagementBottomBanner from "./components/ManagementBottomBanner";
import ManagementLife from "./components/ManagementLife";

import bg from "../assets/images/management/main-bg.svg";
import Helmet from "react-helmet";

function Management() {

  return (
    <div>
      <Helmet>
        <title>Hudson Valley Property Management | Cornerstone Property Management</title>
        <meta property="title" content="Hudson Valley Property Management | Cornerstone Property Management"/>
        <meta property="description" content="It’s your property. But it’s our responsibility. The Hudson Valley’s most complete Property Management team. Get your property seen, occupied, and profitable. PLUS secure your free property analysis."/>
      </Helmet>
      <HeroSection
        link="/quote"
        image={bg}
        imgClassname="hero-container--hoa-img"
        titleWrapperClassname="hero-container--management"
        title={
          <h1 className="hero-container--title">
            Your Complete Hudson Valley Property Management Company
          </h1>
        }
        label="Start My Free Property Analysis" />
      <ManagementWork />
      <ManagementTable />
      <ManagementLife />
      <ManagementBottomBanner />
      <LearnMore page="property_management" />
    </div>
  )
}

export default Management;
