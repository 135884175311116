import React from "react";

import HeroSection from "../shared/HeroSection/HeroSection";
import AboutTeam from "./components/AboutTeam";

import bg from "../assets/images/about/main-bg.jpg";
import Helmet from "react-helmet";

function About() {

  return (
    <div>
      <Helmet>
        <title>Cornerstone Property Management | About Us</title>
        <meta property="title" content="Your Ultimate Hudson Valley Property Management Team | Building, Restoration, Rentals + Sales | Cornerstone Property Management"/>
        <meta property="description" content="Meet the team that will make your Hudson Valley property dreams come true. Put decades of experience in your pocket for property management, real estate, and construction."/>
      </Helmet>
      <HeroSection
        link="/contact"
        btnClassname="hero-container--about-btn"
        innerContainerClassname="hero-container--about-inner"
        image={bg}
        title={
          <h1 className="hero-container--title">
              Meet Your Hudson Valley <br className="crn-br-desktop"/> Property Management Team
          </h1>
        }
        label="I’d Like to Talk"/>
      <AboutTeam/>
    </div>
  )
}

export default About;
