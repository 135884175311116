import React from "react";
import { NavLink } from "react-router-dom";

import "./styles.scss";

type Dropdown = {
    items: { [key: string]: any };
    className?: string;
}

function Dropdown({ items, className }: Dropdown) {

  const classNames = ["dropdown--properties"];

  if (className) classNames.push(className);

  return (
    <div className="dropdown">
      <div className={classNames.join(" ")}>
        {
          items.map(({ label, image, to }: { label: string; image: string; to: string }, key: any) => (
            <NavLink key={key} className="dropdown--properties__item" to={to}>
              <img className="mr-2" src={image} alt=""/>
              {label}
            </NavLink>
          ))
        }
      </div>
    </div>
  )
}

export default Dropdown;
