import React, {useEffect} from "react";
import { Row, Col } from "antd"
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { useHistory } from "react-router";
import emailjs from "emailjs-com";

import "./styles.scss";

import TextArea from "../../shared/TextArea/TextArea";
import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";

import location from "../../assets/images/contact/location.svg"
import phone from "../../assets/images/contact/phone.svg"
import fax from "../../assets/images/contact/fax.svg"
import mail from "../../assets/images/contact/mail.svg"
import GetQuoteBottomSection from "../../GetQuote/components/GetQuoteBottomSection";

const schema = yup.object().shape({
  name: yup.string().required("Field is required").matches(/.*\S.*/, "Field is required"),
  email: yup.string().required("Field is required")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Wrong email format"),
  comments: yup.string().required("Field is required").matches(/.*\S.*/, "Field is required"),

});

function ContactForm() {

  const [isSubmited, setSubmited] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();

  const methods = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = (values: any) => {

    values.type = "Contact";

    sendFeedback(values);
  };

  const sendFeedback = (values: any) => {
    setLoading(true);
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID as string,
        process.env.REACT_APP_TEMPLATE_ID as string,
        values,
        process.env.REACT_APP_USER_ID)
      .then(() => {
        setSubmited(true);
        window.scrollTo(0, 0);
      })
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    if (!isSubmited) return;

    history.push("/contact-thankyou")
  },[isSubmited]);

  return (
    <div className="contact-container--iframe">
      <div>
        <div className="page-container">
          <Row className="contact-container">
            <Col xl={12}>
              <div>
                <h2 className="contact-container--second-title">
                      HUDSON VALLEY PROPERTY MANAGEMENT
                </h2>
                <div className="contact-container--img-container">
                  <img className="contact-container--img" src={location} alt="" />
                  <span className="contact-container--text-icon">560 Main Street <br /> Poughkeepsie, NY 12601 </span>
                </div>
                <div className="contact-container--img-container">
                  <img className="contact-container--img" src={phone} alt="" />
                  <a href="tel:+18455014141" className="contact-container--text-icon">845 501 4141</a>
                </div>
                <div className="contact-container--img-container">
                  <img className="contact-container--img" src={mail} alt="" />
                  <a href="mailto:OFFICE@CORNERSTONEMGMT.US" className="contact-container--text-icon">OFFICE@CORNERSTONEMGMT.US</a>
                </div>
              </div>
            </Col>
            <Col xl={12}>
              <div>
                <FormProvider {...methods}>

                  <form className="contact-container--form">
                    <h3 className="contact-container--title">Need more details? Let's talk</h3>
                    <div className="contact-container--form-inner">

                      <Input className="contact-container--input contact-container--first-input" name="name" placeholder="Your name" label="Name" />

                      <Input className="contact-container--input" name="email" placeholder="Email" label="Email" />

                      <Input className="contact-container--input" name="phone" placeholder="Phone number" label="Phone #" />

                      <TextArea className="contact-container--input" name="comments" label="Comments" placeholder="Message" />

                      <div className="contact-container--btn-container text-center">
                        <Button
                          loading={loading}
                          onClick={methods.handleSubmit(onSubmit)}
                          className="contact-container--btn"
                          label="Submit"
                          type="primary"
                        />
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </Col>
          </Row>
        </div>
        <iframe width="100%" height="347px" id="gmap_canvas"
          src="https://www.google.com/maps/embed/v1/place?q=560+main+street+POUGHKEEPSIE&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          frameBorder="0" scrolling="no"></iframe>
      </div>
    </div>
  )
}

export default ContactForm
