import React from "react";
import { NavLink } from "react-router-dom";

import Button from "../../shared/Button/Button";

import "./styles.scss";

function HomeTeam() {

  return (
    <div className="home-team-container">
      <div className="home-team-container--first-block"/>
      <div className="home-team-container--second-block"/>
      <div className="home-team-container--third-block"/>
      <div className="home-team-container--fourth-block"/>
      <div className="home-team-container--center-container">
        <h2 className="home-team-container--title">
            Dive into your Property Management Solution.<br/> Meet your income property specialists.
        </h2>
        <NavLink to="/about">
          <Button className="home-team-container--btn" label="Meet Your Team"/>
        </NavLink>
      </div>
    </div>
  )
}

export default HomeTeam;
