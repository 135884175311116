import React from "react";
import { useHistory } from "react-router";

import "./styles.scss";


import Button from "../../shared/Button/Button";


import GetQuoteBottomSection from "../../GetQuote/components/GetQuoteBottomSection";

function ContactForm() {

  const history = useHistory();

  return (
    <div className="contact-container--iframe">
      <div className="page-container contact-container--second-step">
        <h3 className="second-title">
          Thank you!
        </h3>
        <p className="second-text">
          We’ll be reaching out to you shortly.
        </p>
        <Button onClick={() => history.push("/home")} className="back-btn" label="Back Home" type="primary" />
        <GetQuoteBottomSection />
      </div>

    </div>
  )
}

export default ContactForm
