import React from "react";
import LazyLoad from "react-lazyload";
import { Col, Row } from "antd";

import "./styles.scss";

import bg from "../../assets/images/services/middle-bg.jpg";

const LINKS = [
  {
    label: "Your time and money are on the line",
    text: "  Construction and restoration projects can get derailed with unexpected costs and delays.\n" +
            "But thoroughly planning and estimating your build means we can identify potential issues\n" +
            "and be realistic about feasibility…and you’re saved\n" +
            "from unpleasant surprises down the line."
  },
  {
    label: "Partnerships make your project run smoother.",
    text: " You know there are a lot of hands working to make your project a success.\n" +
        "But that doesn’t have to mean you’re left trying to mitigate the chaos.\n" +
        "Your construction management team works with architects, designers, safety professionals,\n" +
        "and inspectors to keep your build focused and efficient."
  },
  {
    label: "You’re in control, but we’re on the ground",
    text: "You’re always represented at the site, without feeling like you always have to be there.\n" +
        "Your vision, budget, and schedule guide the work and your input steers decisions."
  }
]

function BuildingControl() {

  return (
    <div className="building-control">
      <LazyLoad>
        <img className="building-control--img" src={bg} alt=""/>
      </LazyLoad>
      <div className="building-control--inner-container">
        <h2 className="building-control--title">
            You don’t want to wake up at night worrying about <br/> your project.
        </h2>
        <Row>
          {
            LINKS.map(({ label, text }, key) => (
              <Col key={key} xl={12} md={24}>
                <div className="building-control--inner-block">
                  <p className="building-control--subtitle">
                    {label}
                  </p>
                  <p className="building-control--text">
                    {text}
                  </p>
                </div>
              </Col>
            ))
          }
        </Row>
      </div>
    </div>
  )
}

export default BuildingControl;
