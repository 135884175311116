import React from "react";
import { Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./styles.scss";

import logo from "../../assets/images/home/footer-logo.png";
import realtorIcon from "../../assets/images/footer-realtor.png";


function Footer() {
  return (
    <div className="footer">
      <div className="page-container">
        <Row>
          <Col lg={6} md={12} sm={24} xs={24}>
            <img src={logo} className="footer--logo" alt="" />
            <img src={realtorIcon} className="footer--realtor-icon" alt="Fair Housing Notice" />
            <p className="footer--all-rights-reserved">
              © {new Date().getFullYear()} Cornerstone Property <br /> Management. All Rights Reserved.
            </p>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <NavLink to="/services/management">
              <p className="footer--text">
                Property Management
              </p>
            </NavLink>
            <NavLink to="/services/hoa">
              <p className="footer--text">
                Condo + HOA Management
              </p>
            </NavLink>
            <NavLink to="/services/building">
              <p className="footer--text">
                Building + Restoration
              </p>
            </NavLink>
            <NavLink to="/services/sales">
              <p className="footer--text">
                Residential + Commercial Sales
              </p>
            </NavLink>
            <NavLink to="/rentals">
              <p className="footer--text">
                Available Properties
              </p>
            </NavLink>
            <NavLink to="/resources">
              <p className="footer--text">
                Resources
              </p>
            </NavLink>
            <NavLink to="/contact">
              <p className="footer--text">
                Contact Us
              </p>
            </NavLink>
            <NavLink to="/about">
              <p className="footer--text">
                About Us
              </p>
            </NavLink>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <NavLink to="/contact">
              <p className="footer--text footer--grey">
                Get in Touch
              </p>
            </NavLink>
            <a href="tel:+18455014141" className="footer--text">845 501 4141</a>
            <a href="mailto:office@cornerstonemgmt.us" className="footer--text footer--email">office@cornerstonemgmt.us</a>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <p className="footer--text footer--grey footer--line-mobile no-cursor">
              Address
            </p>
            <p className="footer--text footer--line-mobile no-cursor">
              560 Main St.
            </p>
            <p className="footer--text footer--line-mobile no-cursor">
              Poughkeepsie, NY 12601
            </p>
          </Col>
          <Col className="footer--all-rights-reserved-mobile" lg={6} md={12} sm={24} xs={24}>
            <p>
              © {new Date().getFullYear()} Cornerstone Property <br /> Management. All Rights Reserved.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Footer;
