import React from "react";

import HeroSection from "../shared/HeroSection/HeroSection";
import BuildingWork from "./components/BuildingWork";
import Reviews from "../shared/Reviews/Reviews";
import BuildingControl from "./components/BuildingControl";
import BuildingRenovation from "./components/BuildingRenovation";


import buildingBg from "../assets/images/services/building-bg.jpg";
import Helmet from "react-helmet";

function Building() {

  return (
    <div>
      <Helmet>
        <title>Hudson Valley Construction + Restoration | Cornerstone Property Management</title>
        <meta property="title" content="Hudson Valley Construction + Restoration | Cornerstone Property Management"/>
        <meta property="description" content="You need high-quality workmanship delivered on time and within budget. You need the Hudson Valley’s Construction and Restoration experts."/>
      </Helmet>
      <HeroSection
        image={buildingBg}
        link="/quote"
        btnClassname="hero-container--building-btn"
        titleWrapperClassname="hero-container--management"
        title={
          <h1 className="hero-container--title">
              Your Hudson Valley Construction <br/> + Restoration Experts
          </h1>
        }
        label="Get my quote"
      />
      <BuildingWork/>
      <BuildingControl/>
      <BuildingRenovation/>
      <Reviews className="reviews-container--title-small"/>
    </div>
  )
}

export default Building;
