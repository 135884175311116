import React from "react";
import LazyLoad from "react-lazyload";
import "./styles.scss";

import first from "../../assets/images/quote/first-quote.jpg";
import second from "../../assets/images/quote/second-quote.jpg";
import third from "../../assets/images/quote/third-quote.jpg";

const BLOCKS = [
  {
    text: "Access Resources",
    img: first
  },
  {
    text: "Explore Properties",
    img: second
  },
  {
    text: "Additional Services",
    img: third
  }
];

function GetQuoteBottomSection() {

  return (
    <div className="page-container">
      <div className="get-quote-bottom-container">
        {
          BLOCKS.map(({ text, img }: { text: string; img: string }) => (
            <div key={text} className="get-quote-bottom-container--block">
              <LazyLoad>
                <img className="get-quote-bottom-container--img" src={img} alt={`${text} Image`} />
              </LazyLoad>
              <p className="get-quote-bottom-container--text">
                {text}
              </p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default GetQuoteBottomSection;
