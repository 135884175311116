import React from "react"
import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";

import "./styles.scss";

import Button from "../../shared/Button/Button";

import house from "../../assets/images/services/building-rectangle.svg";
import arrow from "../../assets/images/services/service-arrow.svg";

function BuildingWork() {

  return (
    <div className="page-container">
      <Row className="building-work">
        <Col xl={16} md={24}>
          <h2 className="building-work--title">
            “I need high-quality workmanship. Completed on time and within budget.”
          </h2>
          <p className="building-work--first-text">
            It’s more than a project to be managed. More than a building to be built or restored.
            It’s your project. And you need it done without surprises, disappointments, or set-backs.
          </p>
          <p className="building-work--second-text">
            You’ll get years of experience in managing and completing new builds and restorations
            within definitive budgets and tight timeframes with our in-house general contractor.
          </p>
          <div className="building-work--arrows-list">
            <div className="building-work--arrow-circle">
              <img src={arrow} alt="" />
            </div>
            <p className="building-work--second-text building-work--second-text-li">
              Lock in the long-term value of your project with professional, quality workmanship.
            </p>
          </div>
          <div className="building-work--arrows-list">
            <div className="building-work--arrow-circle">
              <img src={arrow} />
            </div>
            <p className="building-work--second-text building-work--second-text-li">
              Protect your wallet with construction management that delivers your
              build at or under budget.
            </p>
          </div>
          <div className="building-work--arrows-list">
            <div className="building-work--arrow-circle">
              <img src={arrow} alt="" />
            </div>
            <p className="building-work--second-text building-work--second-text-li">
              Never have to guess the status of your build.
              You’ll be continuously and consistently updated throughout.
            </p>
          </div>
          <div className="building-work--arrows-list">
            <div className="building-work--arrow-circle">
              <img src={arrow} alt="" />
            </div>
            <p className="building-work--second-text building-work--second-text-li">
              Circle that completion date in pen – your project timeline isn’t just a suggestion.
            </p>
          </div>
          <NavLink to="/contact">
            <Button className="building-work--btn" label="Let’s Talk About My Project" type="primary" />
          </NavLink>
        </Col>
        <Col xl={8} md={24}>
          <div className="building-work--grey-container">
            <img className="building-work--grey-container__img" src={house} alt="Image for Construction Restoration Experts" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default BuildingWork;
