import React from "react";
import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";

import "./styles.scss";

import Button from "../../shared/Button/Button";

import house from "../../assets/images/sales/sales-house.svg";


function SalesWork() {

  return (
    <div className="page-container">
      <Row className="sales-work">
        <Col xl={16} md={24}>
          <h2 className="sales-work--title">
            Finding your perfect Hudson Valley spot isn’t easy.
            Letting Cornerstone do
            the footwork for you is.
          </h2>
          <p className="sales-work--second-text">
            Scanning available listings on real estate sites is one thing. But it can get tiring.
            Time-consuming. And pretty old pretty fast.
          </p>
          <p className="sales-work--second-text sales-work--second-text-margin">
            So imagine if you had someone to do that work for you.
          </p>
          <p className="sales-work--second-text">
            The home you’ve always wanted or the business location you’ve been counting on?
            You’ll have deep community connections and years of experience in your back pocket to power your search.
            The real estate team at Cornerstone Property Management puts their network into motion to bring
            that property to you.
          </p>
          <NavLink to="/rentals">
            <Button className="sales-work--btn" label="I’d Like to See Your Properties" type="primary" />
          </NavLink>
        </Col>
        <Col xl={8} md={24}>
          <div className="sales-work--grey-container">
            <img className="sales-work--grey-container__img" src={house} alt="Image for Business Property Sales" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SalesWork;
