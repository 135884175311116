import React from "react";

import HeroSection from "../shared/HeroSection/HeroSection";
import GetQuoteForm from "./components/GetQuoteForm";

import resourcesBg from "../assets/images/resources/Bg@2x.jpg";
import resourcesBgMobile from "../assets/images/resources/resources-mobile-bg.jpg";
import { useWindowDimension } from "../../hooks/useDimensions";
import GetQuoteBottomSection from "./components/GetQuoteBottomSection";
import Helmet from "react-helmet";

function GetQuote() {

  const [isMobile, setMobile] = React.useState(false);
  const [width] = useWindowDimension();

  React.useEffect(() => {
    if (width > 568) setMobile(true);
    else setMobile(false)
  }, [width]);

  return (
    <div>
      <Helmet>
        <title>Cornerstone Property Management | Get a Quote</title>
        <meta property="title" content="Cornerstone Property Management | Get a Quote"/>
        <meta property="description" content="Get a Quote or Start Your Assessment."/>
      </Helmet>
      <HeroSection
        image={!isMobile ? resourcesBgMobile : resourcesBg}
        imgClassname="hero-container--resources-img"
        btnClassname="hero-container--resources-btn"
        titleWrapperClassname="hero-container--resources-quote"
        title={
          <h1 className="hero-container--title">
            Get a Quote or Start Your Assessment
          </h1>
        }
      />
      <GetQuoteForm />
      <GetQuoteBottomSection />
    </div>
  )
}

export default GetQuote;
