import React from "react";
import { Row, Col } from "antd";
import { NavLink } from "react-router-dom";

import property from "../../assets/images/home/services/property.svg";
import hoa from "../../assets/images/home/services/hoa.svg"
import sales from "../../assets/images/home/services/sales.svg"
import restoration from "../../assets/images/home/services/restoration.svg"
import arrow from "../../assets/images/home/services/arrow.svg"


import "./styles.scss";

function HomeServices() {

  return (
    <div className="services-container">
      <div className="page-container">
        <h2 className="services-container--title">
            Our Services
        </h2>
        <Row className="services-container--block-container">
          <Col lg={12} sm={12} xs={24} xl={6}>
            <NavLink to="/services/management">
              <div className="services-container--block">
                <img src={property} alt=""/>
                <h3 className="services-container--block__title">PROPERTY MANAGEMENT</h3>
                <p className="services-container--block__text">
                  Your investment deserves reliable, feet on the ground management.
                </p>
                <img className="services-container--block__arrow" src={arrow} alt=""/>
              </div>
            </NavLink>
          </Col>
          <Col lg={12} sm={12} xs={24} xl={6}>
            <NavLink to="/services/hoa">
              <div className="services-container--block">
                <img src={hoa} alt=""/>
                <h3 className="services-container--block__title">CONDO/HOA MANAGEMENT</h3>
                <p className="services-container--block__text">
                  We handle the ins and outs so you don’t have to.
                </p>
                <img className="services-container--block__arrow" src={arrow} alt=""/>
              </div>
            </NavLink>
          </Col>
          <Col lg={12} sm={12} xs={24} xl={6}>
            <NavLink to="/services/sales">
              <div className="services-container--block">
                <img src={sales} alt=""/>
                <h3 className="services-container--block__title">Rentals <br/> And Sales</h3>
                <p className="services-container--block__text">
                  Expert residential and commercial rental and sale representation.
                </p>
                <img className="services-container--block__arrow" src={arrow} alt=""/>
              </div>
            </NavLink>
          </Col>
          <Col lg={12} sm={12} xs={24} xl={6}>
            <NavLink to="/services/building">
              <div className="services-container--block">
                <img src={restoration} alt=""/>
                <h3 className="services-container--block__title services-container--block__restoration">Building And Restoration</h3>
                <p className="services-container--block__text">
                  In-house construction services to create the property you’ve always imagined.
                </p>
                <img className="services-container--block__arrow" src={arrow} alt=""/>
              </div>
            </NavLink>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default HomeServices;
