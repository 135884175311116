import React from "react";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";

import "./styles.scss";
import Button from "../Button/Button";


type HeroSection = {
    image: string;
    title: React.ReactElement;
    label?: string;
    titleWrapperClassname?: string;
    btnClassname?: string;
    imgClassname?: string;
    innerContainerClassname?: string;
    searchBar?: React.ReactElement;
    link?: string;
    linkOuter?: string;
}

function HeroSection({
  image,
  title,
  label,
  titleWrapperClassname,
  btnClassname,
  imgClassname,
  innerContainerClassname,
  searchBar,
  link,
  linkOuter,
}: HeroSection) {

  const titleWrapperClassnames = ["hero-container--title-wrapper"];
  const btnClassnames = ["hero-container--btn"];
  const imgClassnames = ["hero-container--img"];
  const innerContainerClassnames = ["hero-container--inner-container"];
  let btn = null;


  if (titleWrapperClassname) titleWrapperClassnames.push(titleWrapperClassname);
  if (btnClassname) btnClassnames.push(btnClassname);
  if (imgClassname) imgClassnames.push(imgClassname);
  if (innerContainerClassname) innerContainerClassnames.push(innerContainerClassname);

  if (label && link) {
    btn = (
      <NavLink to={link}>
        <Button className={btnClassnames.join(" ")} label={label} />
      </NavLink>
    );
  }
  if (label && !link) {
    btn = (
      <Button className={btnClassnames.join(" ")} label={label} />
    )
  }

  if (label && linkOuter) {
    btn = (
      <a href={linkOuter} target="_blank" rel="noopener noreferrer">
        <Button className={btnClassnames.join(" ")} label={label} />
      </a>
    )
  }

  return (
    <div className="hero-container">
      <LazyLoad>
        <img className={imgClassnames.join(" ")} src={image} alt=""/>
      </LazyLoad>
      <div className={innerContainerClassnames.join(" ")}>
        <div className={titleWrapperClassnames.join(" ")}>
          {title}
        </div>
        {btn}
        {searchBar}
      </div>
    </div>
  )
}

export default HeroSection;
