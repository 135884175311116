import React from "react"
import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazyload";

import "./styles.scss";

import Button from "../../shared/Button/Button";

import house from "../../assets/images/management/house-img.svg";
import arrow from "../../assets/images/services/service-arrow.svg";

const WORK_LIST = [
  "Amplify your property’s visibility and open prospective tenants’ eyes to its value with customized marketing",
  "Secure the right tenant for your property with extensive vetting",
  "Streamline income with rent collection and a user-friendly tenant portal for payments",
  "Insulate your investment against code violations with efficient, expedient maintenance management",
  "Protect the reputation of your property by continuously exceeding tenant expectations",
  "Keep your finger on the pulse of your property’s success with a 24/7 owner portal"
];

function ManagementWork() {

  return (
    <div className="page-container">
      <Row className="management-work">
        <Col xl={16} md={24}>
          <h2 className="management-work--title">
              Your property should work for you. We’re here to make that happen.
          </h2>

          <p className="management-work--second-text">
              You invested in the Hudson Valley. Now you need feet on the ground to keep that investment operating efficiently and profitably. With a reliable local team, you can remove yourself from the day-to-day frustrations and still be confident that your property will generate income.
          </p>
          <p className="management-work--second-text management-work--second-text-margin">
              Deep connections to and roots in the communities of the Hudson Valley give you the network your property needs to be seen, be occupied, and be profitable.
          </p>
          <p className="management-work--second-text">
              We’re local so you don’t have to be. With decades of experience and properties in Dutchess and Ulster Counties, we are your income property specialists.
          </p>
          <p className="management-work--first-text">
              Cornerstone Property Management is the single most effective way to maximize your investment.
          </p>
          {
            WORK_LIST.map(el => (
              <div key={el} className="management-work--arrows-list">
                <div className="management-work--arrow-circle">
                  <img src={arrow} alt=""/>
                </div>
                <p className="management-work--second-text management-work--second-text-li">
                  {el}
                </p>
              </div>
            ))
          }
          <NavLink to="/contact">
            <Button className="management-work--btn" label="Start My Zero-Cost Property Analysis" type="primary"/>
          </NavLink>
        </Col>
        <Col xl={8} md={24}>
          <div className="management-work--grey-container">
            <LazyLoad>
              <img className="management-work--grey-container__img" src={house} alt="Image for Property Management"/>
            </LazyLoad>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ManagementWork;
