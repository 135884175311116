import React from "react";
import { NavLink } from "react-router-dom";

import "./styles.scss";

import Button from "../../shared/Button/Button";
import Table from "../../shared/Table/Table";

import first from "../../assets/images/management/star.svg";
import second from "../../assets/images/management/community.svg";
import third from "../../assets/images/management/house-icon.svg";
import fourth from "../../assets/images/management/Hammer.svg";
import fifth from "../../assets/images/management/money.svg";
import six from "../../assets/images/management/notes.svg";

const TABLE_ITEMS = [
  {
    title: "Nail Your Marketing",
    text: "Your property needs to be seen and valued above any others. Professional photography, compelling descriptions, and a network of listing resources gets you the visibility you need to keep your investment occupied. And our property analysis means it will be priced competitively but profitably.",
    img: first
  },
  {
    title: "Collect Rent Reliably",
    text: "Eliminate roadblocks to rent collection with an easy-to-navigate tenant portal and drop-off payment options. Simple, streamlined, and designed to make rent easy for you and your tenants.",
    img: second
  },
  {
    title: "Manage Your Maintenance",
    text: "A well-maintained property helps preserve its value and ensure tenant satisfaction. Our team inspects your property before and after occupancy, ensures maintenance requests are addressed completely and quickly, and keeps your building and property desirable.",
    img: third
  },
  {
    title: "Get and Stay Up-to-Code",
    text: "Value your property? Then code violations can’t be ignored. Get the expert team with the experience to correct violations completely and efficiently…and then keep you violation-free.",
    img: fourth
  },
  {
    title: "Know Your Numbers",
    text: "Get instant access to your financials and property performance with our owner portal. Easily run and export income reports, quickly analyze current and expiring leases, track any delinquent rentals, and more – all behind a single sign-on.",
    img: fifth
  },
  {
    title: "Mitigate Evictions",
    text: "Evictions are a reality of ownership, but your exposure to them is mitigated by the Cornerstone team. You’ll receive the updates and information you need throughout the proceedings but we’ll navigate the entire process for you.",
    img: six
  }
];

function ManagementTable() {

  return (
    <div className="page-container">
      <div className="management-table">
        <h2 className="management-table--title">
          The solution owners use to make sure their property is maintained, occupied, up-to-code,
            and profitable…without having to do it themselves.
        </h2>

        <Table isDouble items={TABLE_ITEMS} />

        <div className="text-center">
          <NavLink to="/contact">
            <Button className="management-table--btn" label="I’m Ready to Talk" />
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default ManagementTable;
