import React from "react";

import hoaBg from "../assets/images/hoa/hoa-bg.jpg";

import HeroSection from "../shared/HeroSection/HeroSection";
import HOAManagement from "./components/HOAManagement";
import HOATable from "./components/HOATable";
import Reviews from "../shared/Reviews/Reviews";
import HOATeam from "./components/HOATeam";

function HOA() {

  return (
    <div className="hoa-container">
      <HeroSection
        link="/quote"
        image={hoaBg}
        titleWrapperClassname="hero-container--management"
        imgClassname="hero-container--hoa-img"
        title={
          <h1 className="hero-container--title">
            Your Hudson Valley Condo +
            <br className="hero-container--mobile-view-canceled" />
            HOA Management Specialists
          </h1>
        }
        label="Get my quote"
      />
      <HOAManagement />
      <HOATable />
      <Reviews />
      {/* <HOATeam/> */}
    </div>
  )
}

export default HOA;
