import React from "react";
import { Spin } from "antd";

import "./styles.scss";

type Button = {
    label: string;
    className?: string;
    type?: string;
    onClick?: () => void;
    loading?: boolean;
}

function Button({ label, className, type, onClick, loading }: Button) {

  const classNames = ["crn-button"];

  if (className) classNames.push(className);
  if (type === "primary") classNames.push("crn-button--primary");
  if (type === "secondary") classNames.push("crn-button--secondary");
  if (loading) classNames.push("crn-button--loading");


  return (
    <button disabled={loading} onClick={onClick} className={classNames.join(" ")}>
      {!loading ? label : (<div>
        <Spin size="small" className="crn-button--spinner"/>
          Loading
      </div>)}
    </button>
  )
}

export default Button;
