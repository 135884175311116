import React from "react";
import { NavLink } from "react-router-dom";

import logo from "../../assets/images/home/logo-dark.svg";
import logoOpen from "../../assets/images/home/header-open-logo.svg";
import arrow from "../../assets/images/home/vector.svg";
import resident from "../../assets/images/home/residante.svg";
import commercial from "../../assets/images/home/commercial.svg";
import restoration from "../../assets/images/home/restoration.svg";
import hoa from "../../assets/images/home/hoa.svg";
import property from "../../assets/images/home/property.svg";
import sales from "../../assets/images/home/sales.svg";

import "./styles.scss";

import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import { useWindowDimension } from "../../../hooks/useDimensions";
import Burger from "../Burger/Burger";

const SERVICES_LIST = [
  {
    label: "Commercial",
    image: commercial,
    to: "/rentals-commercial"
  },
  {
    label: "Residential",
    image: resident,
    to: "/rentals"
  }
];

const PROPERTIES_LIST = [
  {
    label: "Property Management",
    image: property,
    to: "/services/management"
  },
  {
    label: "Conda/Hoa Management",
    image: hoa,
    to: "/services/hoa"
  },
  {
    label: "Residential + Commercial Sales",
    image: sales,
    to: "/services/sales"
  },
  {
    label: "Construction + Restoration",
    image: restoration,
    to: "/services/building"
  }
];

const HEADER_LIST = [
  {
    label: "Services",
    arrow: arrow,
    dropdown: <Dropdown items={PROPERTIES_LIST} />,
    to: "/services",
    isNoLink: true
  },
  {
    label: "Properties",
    arrow: arrow,
    dropdown: <Dropdown items={SERVICES_LIST} className="dropdown--services" />,
    to: "/properties",
    isNoLink: true
  },
  {
    label: "About Us",
    to: "/about"
  },
  {
    label: "Get a Quote",
    to: "/quote"
  },
  {
    label: "Contact Us",
    to: "/contact"
  },
  {
    label: "Resources",
    to: "/resources"
  },

];

function Header() {

  const [open, setOpen] = React.useState(false);
  const [width] = useWindowDimension();

  React.useEffect(() => {
    if (width > 768) setOpen(false);
  }, [width]);

  return (
    <div className="header-container-main">
      <header className={`header-container ${open ? "header-container--open" : "header-container--closed"} `}>
        <div className="page-container">
          <div className="header-container--upper">
            <NavLink to="/">
              <img src={open ? logoOpen : logo} alt="" />
            </NavLink>
            <div className="header-container--btn-container">
              <NavLink to="/rentals">
                <Button label="Featured Properties" type="secondary" />
              </NavLink>
              <a target="_blank" rel="noopener noreferrer" href="https://cornerstonemgmt.managebuilding.com/Resident/public/home">
                <Button label="Apply Now" className="ml-2" type="primary" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <div className="header-bottom">
        <div className="header-bottom--inner">
          {
            HEADER_LIST.map((el, key) => (
              <div className="m-0 header-bottom--inner__item" key={key}>

                <NavLink
                  style={{ pointerEvents: el.isNoLink ? "none" : "auto" }}
                  activeClassName="header-container--link__active"
                  to={el.to as string}
                  className="header-container--link">
                  {el.label}
                </NavLink>
                {el.arrow && <img className="header-bottom--inner__arrow" src={el.arrow} alt="" />}
                {el.dropdown && el.dropdown}
              </div>
            ))
          }
        </div>
      </div>

      <Burger
        open={open}
        setOpen={setOpen}
      />
    </div>
  )
}

export default Header;
