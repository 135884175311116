import React from "react";

import { itemsContainer } from "../../shared/LearnMore/LearnMore";

function ResourcesGuideline() {

  return (
    <div className="page-container">
      <div className="resources-guideline-container">
        <h2 className="resources-guideline-container--title">
          You’re looking for a little bit of <br className="crn-br-desktop" /> guidance, please.
        </h2>
        <p className="resources-guideline-container--text">
          Start with our resource library. We’ve pulled together expert information on:
        </p>

        {itemsContainer({ page: "resourses" })}

        <p className="resources-guideline-container--text-second">
          Navigate your decision with a little more clarity. Download your free resources first.
        </p>
      </div>
    </div>
  )
}

export default ResourcesGuideline;
